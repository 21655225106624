.adminToolsTitleRow {
    width: 100%;
    display: inline-block !important;
}

.adminToolsTitle {
    font-size: 5vw;
    float: left;
    width: 30%;
    margin-left: 15vw;
    font-family: 'Didot';
}

.adminToolsPanel {
    width: 20vw;
    margin: 1vw;
}

.adminToolsPanel .rs-panel-body {
    width: 100%;
    height: 25vw;
    margin: 0 auto;
    margin-top: 5vw;
    text-align: center;
}

.adminToolsImage {
    font-size: 10vw;
}

.adminToolsImage .rs-icon {
    cursor: pointer;
    text-align: center !important;
}

#adminToolsImagesRow1 {
    /* height: 25vw; */
    padding-bottom: 2vw;
    width: 90%;
    margin: 0 auto;
    margin-top: 5vw;
    align-content: center;
}


/* ----------- LAPTOPS ----------- */


/* ----------- Non-Retina Screens ----------- */

@media screen 
and (min-device-width: 700px) 
and (max-device-width: 1100px)  { 
   
}

@media screen 
and (min-device-height: 1000px)
and (max-device-width: 699px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 700px) 
and (max-device-width: 1200px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 1201px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

@media screen 
and (min-device-width: 1601px) 
and (max-device-width: 1800px) 
and (-webkit-min-device-pixel-ratio: 1) { 

}

@media screen 
and (min-device-width: 1800px) 
and (max-device-width: 2000px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

@media screen 
and (min-device-width: 2001px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {
  
}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
 
}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 834px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1112px) 
and (max-device-width: 1112px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
 
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1366px) 
and (max-device-width: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPhones ----------- */


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    .adminToolsPanel {
        height: 50vw !important;
        width: 40vw;
    }

    .adminToolsImage {
        font-size: 20vw;
    }

    /* MODALS */

    #adminAddFormHeading {
        font-size: 7vw;
        font-weight: bold;
        margin-bottom: 5vw;
    }

    .adminDeleteFormGroup {
        height: 50vw;
    }

}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
  
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (min-device-height: 365px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 
  

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 
  
  
}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}






