/* NAVBAR BODY */
#navbarHome {
    background-color: transparent;
    width: 100% !important;
    margin: 0 auto;
    padding: 7vw;
    display: inline-block;
}

#navbarHomeLogo {
    float: left;
    width: 30vw;
}

#navbarHomeBody {
    width: 100% !important;
    margin: 0 auto;
    display: inline-block !important;
}

.disclaimer {
    text-align: center;
    margin-top: 2vw;
    font-size: .8rem;
}

/* NAVBAR PAGES */
#navbarHomeTabs {
    background-color: transparent;
    color: #929292;
    font-family: 'Georgia-Italic','Georgia';
    height: 2vw;
    line-height: 2vw;
    font-style: italic;
    margin: 0;
    margin-top: 1vw;
    padding: 0;
    padding-left: 2vw;
    text-align: left;
    font-size: 12vw;
    float: right;
}

.navbarHomeItems a {
    font-size: 1rem !important;
}

.navbarHomeItems .rs-nav-item-content :hover {
    background-color: green !important;
    /* background-color: linear-gradient(green, red, yellow) !important; */
}


/* SHOPPING CART */
#navbarHomeCart .rs-icon {
    font-size: 1.5vw !important;
}

#navbarHomeCart .rs-icon:hover {
    background-color: transparent !important;
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
  }
  
.label-warning[href],
.badge-warning[href] {
    background-color: #c67605;
  }

#cartCount {
    font-size: .7rem;
    background: lightblue !important;
    color: black;
    /* padding: 0 0.5vw 0 0.5vw; */
    vertical-align: top;
  }

#cartCount :hover {
    font-size: 1vw;
    background: lightblue !important;
    color: black;
    /* padding: 0 0.5vw 0 0.5vw; */
    vertical-align: top;
  }


/* ----------- LAPTOPS ----------- */


/* ----------- Non-Retina Screens ----------- */
@media screen 
    and (min-device-width: 700px) 
    and (max-device-width: 800px)  { 
        #navbarHomeLogo {
            width: 23vw;
        }
        .navbarHomeItems a {
            font-size: 1.5vw !important;
            padding: 8px 6px !important;
        }
  }
@media screen 
    and (min-device-width: 800px) 
    and (max-device-width: 1100px)  { 
        #navbarHomeLogo {
            width: 25vw;
        }
  }

@media screen 
and (min-device-height: 1000px)
and (max-device-width: 699px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 700px) 
and (max-device-width: 1200px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 1201px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

@media screen 
and (min-device-width: 1601px) 
and (max-device-width: 1800px) 
and (-webkit-min-device-pixel-ratio: 1) { 

}

@media screen 
and (min-device-width: 1800px) 
and (max-device-width: 2000px) 
and (-webkit-min-device-pixel-ratio: 1) { 

}

@media screen 
and (min-device-width: 2001px) 
and (-webkit-min-device-pixel-ratio: 1) { 
    .navbarHomeItems a {
        font-size: 1.5rem !important;
    }
    .cartCount {
        font-size: 1rem;
    }
    .disclaimer {
        font-size: 1.3rem;
    }

}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {
    #navbarHomeLogo {
        width: 100%;
        margin: 0 auto;
    }

    #navbarHomeTabs {
        width: 100%;
        align-items: center;
    }

    #navbarHomeTabs ul {
        margin: 0 auto;
        width: 100%;
    }

    #navbarHomeTabs ul li {
        width: 16%;
        text-align: center;
    }

    .disclaimer {
        margin-top: 3vh;
    }
}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 834px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1112px) 
and (max-device-width: 1112px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
 
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1366px) 
and (max-device-width: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPhones ----------- */


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {
    .disclaimer {
        font-size: .8rem;
    }
}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    #navbarHome {
        padding: 3vw !important;
        margin-bottom: 10vw;
    }
    #navbarHomeBody {
        width: 100vw;
    }
    #navbarHomeLogo {
        width: 90vw;
    }
    #navbarHomeTabs {
        width: 100vw !important;
        margin: 0 auto !important;
        margin-left: 0vw;
        padding-left: 0vw;
        height: 10vw;
        float: none;
        display: inline-block;
    }
    .navbarHomeItems a {
        font-size: 3.5vw !important;
        letter-spacing: 0vw;
        padding: 8px 4px !important;
        height: 10vw !important;
    }
    #navbarHomeCart .rs-icon {
        font-size: 3vw !important;
    }
    .badge {
        padding-left: 1vw;
        padding-right: 1vw;
        border-radius: 1vw;
    }
    #cartCount {
        font-size: 3vw;
    }

}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
    
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .disclaimer {
        font-size: .7rem;
    }
}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 
   
}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (min-device-height: 365px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 
    .disclaimer {
        margin-top: 10vh !important;
    }

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .disclaimer {
        font-size: .8rem;
    }
}

/* Portrait */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 
    .disclaimer {
        font-size: .8rem;
    }
}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 
  
  
}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}







