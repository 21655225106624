#cartRow {
	width: 80%;
	margin: 0 auto;
	margin-bottom: 5vw;
}

.shoppingCartTitle {
	text-align: center;
	margin-top: 5vw;
	margin-bottom: 3vw;
	font-size: 5vw;
	font-family: 'Didot';
}

#cartEdit {
	background-color: #fac04e;
}

#cartRemove {
	background-color: red;
	color: white;
}

.cartEditButtons {
	font-weight: bold;
	padding: 0;
	padding-left: .2rem;
	padding-right: .2rem;
	white-space: nowrap;
	margin: .1rem;
}

.changeQtyButton {
	margin-left: 2vw;
}

#emptyCartTitle {
	text-align: center;
	margin-top: 5vw;
	/* font-size: 18vw; */
}

.rs-table-body-row-wrapper {
	/* max-height: 50vw; */
}

/* #placeOrderRow {
	width: 30vw;
	margin: 0 auto;
	margin-bottom: 10vw;
} */

.shoppingCartButtonsRow {
	width: 30vw;
	margin: 0 auto;
	margin-bottom: 10vw;
}

.shoppingCartButtons {
	width: 30vw;
}

#placeOrderButton {
	background-color: lightgreen;
	width: 30vw;
	align-content: center;
	margin-bottom: 1vw;
}

#emptyCartButton {
	background-color: lightgray;
	width: 30vw;
	align-content: center;
	
}

#placeOrderTotal {
	text-align: center;
	margin-bottom: 2vw;
	font-size: 2vw;
	font-weight: bolder;
}

/* ORDER TABLE */

#orderInfoTableDiv {
	width: auto !important;
	padding: 5rem;
}

.rs-table-body-row-wrapper {
	min-width: inherit !important;
}

.orderTable {
	min-width: inherit !important;
	height: auto !important;
	margin-left: 2.5vw;
	margin-right: 2.5vw;
}

.orderTable .rs-table-row-header {
	min-width: inherit !important;
}

/* ----------- LAPTOPS ----------- */

/* ----------- Non-Retina Screens ----------- */

@media screen and (min-device-height: 1000px) and (max-device-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 700px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1601px) and (max-device-width: 1800px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1800px) and (max-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 2001px) and (-webkit-min-device-pixel-ratio: 1) {
	.cartTable {
		font-size: 1.5rem;
	}
	#emptyCartButton, #placeOrderButton {
		font-size: 2rem;
	}
	#emptyCartButton i, #placeOrderButton i {
		font-size: 2rem;
	}
	#orderInfoTableDiv {
		padding: 15rem;
		font-size: 2rem;
	}

	.orderTable .rs-table-cell-content {
		font-size: 1.5rem !important;
		line-height: 1;
	}
}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
	.rs-dropdown-menu {
		height: 20vh;
		overflow: scroll;
	}
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
	.rs-dropdown-menu {
		height: 20vh;
		overflow: scroll;
	}
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
	.rs-dropdown-menu {
		height: 20vh;
		overflow: scroll;
	}
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
	.rs-dropdown-menu {
		height: 20vh;
		overflow: scroll;
	}
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPhones ----------- */

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	.shoppingCartTitle {
		font-size: 6vw !important;
		margin-bottom: 10vw !important;
	}
	#placeOrderTotal {
		font-size: 4vw !important;
	}
	#placeOrderRow {
		width: 100%;
	}
	#placeOrderButton {
		font-size: 3vw;
	}

	#emptyCartButton {
		font-size: 3vw;
	}

	#placeOrderButton .rs-icon {
		font-size: 3vw;
	}

	#emptyCartButton .rs-icon {
		font-size: 3vw;
	}

	.cartEditButtons {
		margin-left: 2vw;
		font-size: .8rem;
		line-height: 2;
	}

	#cartEditButtonDivider {
		font-size: 1rem;
		margin-left: .5vw;
	}

	#cartSoapQtyCol {
		width: 15vw;
	}

	#cartSoapNameCol,
	#cartSoapPriceCol,
	#cartSoapTotalCol {
		min-width: 20vw;
	}

	#cartSoapPriceCol {
		left: 120px;
	}

	#cartSoapTotalCol {
		left: 180px;
	}

	.rs-table-cell-header {
		min-width: 100%;
	}

	.rs-table-cell-content {
		font-size: 3vw;
		/* width: 100% !important; */
		white-space: pre-wrap;
		overflow: visible;
		padding-top: 1vh;
		line-height: 1 !important;
	}

	/* ORDER TABLE */

	.orderInfoTableDiv {
		width: auto !important;
	}

	.rs-table-body-row-wrapper {
		max-width: inherit !important;
	}

	.orderTable {
		max-width: inherit !important;
		margin-left: 2.5vw;
		margin-right: 2.5vw;
	}

	.orderTable .rs-table-row-header {
		max-width: inherit !important;
	}
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (min-device-height: 365px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
	#orderInfoTableDiv {
		padding: 5vw;
	}
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
