.purchasesTitleRow {
  width: 100%;
  display: inline-block !important;
  margin-bottom: 3vw;
}

.purchasesTitle {
  font-size: 5vw;
  float: left;
  width: 30%;
  margin-left: 15vw;
  font-family: "Didot";
}

.purchasesTable {
  min-height: 50vh;
  max-width: 100vw;
}

#adminPurchasesSearchBarForm {
  float: right;
  width: 30%;
  margin-right: 10vw;
  margin-top: 1vw;
}

#adminPurchasesSearchBarFormGroup .rs-form-control-wrapper {
  max-width: 70% !important;
}

/* .searchIcon {
    float: right;
    margin-top: .5vw;
    margin-right: 1vw;
} */

#purchaseRow {
  width: 80vw;
  margin: 0 auto;
}

.shoppingCartTitle {
  text-align: center;
  margin-top: 5vw;
  margin-bottom: 3vw;
  font-size: 5vw;
}

.purchaseEditButtons {
  padding: 0;
  white-space: nowrap;
}

.changeQtyButton {
  margin-left: 2vw;
}

#emptyPurchasesTitle {
  text-align: center;
  margin-top: 5vw;
  /* font-size: 18vw; */
}

.rs-table-body-row-wrapper {
  height: 50vh !important;
  /* max-height: 50vw; */
}
/* 
#placeOrderRow {
  width: 30vw;
  margin: 0 auto;
  margin-bottom: 10vw;
} */

#placeOrderButton {
  background-color: lightgreen;
  width: 30vw;
  align-content: center;
}

#placeOrderTotal {
  text-align: center;
  margin-bottom: 2vw;
  font-size: 2vw;
  font-weight: bolder;
}

/* #openPurchasesDetailModal {
    
} */

#purchaseForm {
  text-align: center;
}

#purchaseFormHeading {
  font-size: 2vw;
  margin-bottom: 2vw;
}

.purchaseFormGroup {
  margin-bottom: 1vw;
}

.purchaseFormLabel {
  margin-right: 2vw;
}

.purchaseDetailsTable {
  width: 80vw !important;
  /* overflow-x: scroll !important; */
}

#purchaseDetailsTotal {
  text-align: center;
  background-color: lightgreen;
  width: 15vw;
  margin: 0 auto;
  padding: 1vw;
}

/* LOADER */

#purchasesInfoLoader {
  margin-top: 10vw;
  height: 10vw;
  position: relative;
  top: 0;
}

/* ----------- LAPTOPS ----------- */

/* ----------- Non-Retina Screens ----------- */

@media screen and (min-device-width: 700px) and (max-device-width: 1100px) {
}

@media screen and (min-device-height: 1000px) and (max-device-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 700px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1601px) and (max-device-width: 1800px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1800px) and (max-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 2001px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPhones ----------- */

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #purchaseDetailsTotal {
    width: 40vw;
    margin-top: 5vw;
  }

  .purchasesTitle {
    width: 20vw;
    margin-top: 3vw;
  }

  #purchaseRow {
    height: 100vw;
  }

  .purchasesTable {
    height: 100vw !important;
  }

  .purchasesTable .rs-table-body-row-wrapper {
    height: 100vw !important;
  }

  /* SEARCH BAR */

  #adminPurchasesSearchBarForm {
    float: left;
    width: 55vw;
    margin-right: 0vw;
    margin-left: 8vw;
    font-size: 3vw;
  }

  #adminPurchasesSearchBarForm.rs-form-control-wrapper {
    width: 100%;
  }

  #searchEntry {
    max-width: 100%;
    font-size: 2.5vw;
  }
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (min-device-height: 365px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
