#contactUsImagesRow {
    height: 35vw;
    padding-bottom: 4vw;
    width: 90%;
    margin: 0 auto;
    margin-top: 1vw;
    align-content: center;
}

.contactUsImagesCol, .contactUsFormCol {
    width: 50%;
    display: inline-block;
    height: 100%;
    padding: 5vw;
}

.contactUsImage {
    width: 100%;
    height: auto;
    margin: 0 auto;
    
}

/* ----------- LAPTOPS ----------- */


/* ----------- Non-Retina Screens ----------- */

@media screen 
and (min-device-height: 1000px)
and (max-device-width: 699px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 700px) 
and (max-device-width: 1200px) 
and (-webkit-min-device-pixel-ratio: 1) { 
  
}

@media screen 
and (min-device-width: 1201px) 
and (max-device-width: 1600px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

@media screen 
and (min-device-width: 1601px) 
and (max-device-width: 1800px) 
and (-webkit-min-device-pixel-ratio: 1) { 

}

@media screen 
and (min-device-width: 1800px) 
and (max-device-width: 2000px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

@media screen 
and (min-device-width: 2001px) 
and (-webkit-min-device-pixel-ratio: 1) { 
 
}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {
  
}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 1) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
 
}

/* Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 1112px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 834px) 
and (max-device-width: 834px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1112px) 
and (max-device-width: 1112px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px)
and (-webkit-min-device-pixel-ratio: 2) {
  
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) {
 
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
and (min-device-width: 1366px) 
and (max-device-width: 1366px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2) {

}

/* ----------- iPhones ----------- */


/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 480px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    #contactUsFormSpan {
        margin-bottom: 10vw;
    }
    #contactUsImagesRow {
        height: 100%;
        padding-bottom: 4vw;
        width: 90%;
        margin: 0 auto;
        margin-top: 1vw;
        align-content: center;
    }
    
    .contactUsImagesCol, .contactUsFormCol {
        width: 100%;
    }

    #contactForm {
        width: 100%;
    }

    .rs-form-control-wrapper > .rs-input-number, .rs-form-control-wrapper > .rs-input {
        max-width: 100% !important;
    }

    textarea.rs-input {
        max-width: 100% !important;
    }
 
}

/* Landscape */
@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) {
  
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (min-device-height: 365px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 
  

}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 

}

/* Landscape */
@media only screen 
and (min-device-width: 414px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3) { 

}

/* Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: portrait) { 
  
  
}

/* Landscape */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) { 
  
}






