#adminPageTitle {
    text-align: center;
    font-size: 3vw;
    margin-bottom: 2vw;
}

#adminAddPage, #adminDeletePage {
    width: 40vw;
    margin: 0 auto;
    margin-bottom: 10vw;
}

#adminAddForm, #adminDeleteForm {
    text-align: center;
}

#adminAddFormHeading, #adminDeleteFormHeading {
    font-size: 2vw;
    margin-bottom: 2vw;
}


.adminAddFormGroup, .adminDeleteFormHeading {
    margin-bottom: 1vw;   
}

.adminAddFormLabel, .adminDeleteFormLabel {
    margin-right: 2vw;
}

#logoutPage {
    text-align: center;
    margin-top: 10vw;
}

#adminDeleteModalBody {
    padding-bottom: 10vw !important;
}

