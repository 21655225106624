/* PRODUCT ROWS */

#productsImagesRow1 {
	/* height: 25vw; */
	padding-bottom: 2vw;
	width: 90%;
	margin: 0 auto;
	margin-top: 5vw;
	align-content: center;
}

#productsImagesRow2 {
	/* height: 25vw; */
	padding-bottom: 2vw;
	width: 90%;
	margin: 0 auto;
	margin-top: 1vw;
	align-content: center;
}

.productsImagePanel {
	width: 22%;
	height: 100% !important;
	margin: 1vw;
}

.productsImage {
	width: 100%;
	margin: 0 auto !important;
	height: auto;
	cursor: pointer;
	border-radius: 6px;
}

.productListingDetailsCol {
	text-align: center;
}

.productListingImageCol {
	margin: 0 auto !important;
	margin-top: 1vw !important;
}

/* PRODUCT MODALS */

.productsImageModal {
	width: 50%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: auto;
}

.productsImageHeader {
	padding: 1vw !important;
}

.productsImageHeader .rs-panel-heading {
	padding: 1vw !important;
	font-size: 2vw;
	text-align: center;
}

.productsImageHeader .rs-panel-body {
	display: none;
}

.rs-dropdown-menu {
	max-height: 50vh;
	overflow: scroll;
}

#productsListingName {
	text-align: center;
	font-size: 3vw;
}

.productsListingPrice,
.productsListingIngredients {
	font-size: 1rem;
	font-weight: bolder;
	line-height: 1.6;
}

.productsListingIngredients {
	border: none;
	text-align: left;
	resize: none;
	padding: 1vw;
	padding-right: 2vw;
	padding-left: 0;
	/* width: 90%; */
	padding-bottom: 8rem;
	float: left;
	width: 100%;
	height: auto;
	min-height: 75px;
	outline: none;
	resize: none;
	/* border: 1px solid grey; */
}

.productsListingPrice {
	/* margin-top: 1vw; */
	text-align: center;
}

.productsTitleRow {
	width: 100%;
	display: inline-block !important;
}

.productsTitle {
	font-size: 5vw;
	float: left;
	width: 30%;
	margin-left: 15vw;
	font-family: "Didot";
}

/* SEARCH BAR */

#adminProductsSearchBarForm {
	float: right;
	width: 30%;
	margin-right: 10vw;
	margin-top: 1vw;
}

#adminProductsSearchBarFormGroup .rs-form-control-wrapper {
	width: 70%;
}

.searchIcon {
	margin-left: 1vw;
}

#searchEntry {
	width: 100%;
}

/* LOADER */

#productsLoader {
	margin-top: 10vw;
	height: 10vw;
	position: relative;
	top: 0;
}

/* ----------- LAPTOPS ----------- */

/* ----------- Non-Retina Screens ----------- */

@media screen and (min-device-width: 700px) and (max-device-width: 1100px) {
	#searchEntry {
		width: 100%;
	}
	#adminProductsSearchBarFormGroup .rs-form-control-wrapper {
		width: 85%;
	}
}

@media screen and (min-device-height: 1000px) and (max-device-width: 699px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 700px) and (max-device-width: 1200px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1601px) and (max-device-width: 1800px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 1800px) and (max-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media screen and (min-device-width: 2001px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPads ----------- */

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1112px) and (max-device-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPhones ----------- */

/* ----------- iPhone 4 and 4S ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
	.productsTitle {
		width: 20vw;
		margin-top: 1vw;
		margin-left: 15vw;
		font-size: 6vw;
	}

	/* SEARCH BAR */

	#adminProductsSearchBarForm {
		float: left;
		width: 65vw;
		margin-right: 0vw;
		font-size: 3.5vw;
	}

	#adminProductsSearchBarForm.rs-form-control-wrapper {
		width: 100%;
	}

	#searchEntry {
		max-width: 100%;
		font-size: 3.2vw;
	}

	/* PRODUCT ROWS */
	.productsImagePanel {
		width: 45%;
		margin-left: 2.5%;
	}

	.productsImageHeader .rs-panel-heading {
		font-size: 4vw;
	}

	/* PRODUCT MODALS */
	.rs-modal-content {
		max-width: 100vw;
	}
	#productsListingName {
		font-size: 5vw;
	}

	.productsImageModal {
		width: 40vw;

		height: auto;
	}

	.productListingDetailsCol {
		/* max-width: 100%; */

		float: left;
		/* width: 100%; */
		height: auto;
	}

	.productsListingIngredients {
		padding-bottom: 5rem;
	}

	/* .comment {
    
} */

	.productListingDetailsCol p {
		font-size: 5vw;
	}

	.productListingDetailsCol h5 {
		font-size: 5vw;
	}
}

/* Landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (min-device-height: 365px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}

/* ----------- iPhone X ----------- */

/* Portrait and Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
}

/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape) {
}
